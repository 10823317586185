import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { TRANSLATIONS_EN } from "./locales/en/translation";
import { TRANSLATIONS_UK } from "./locales/uk/translation";

const resource = {
    en: {
        translation: TRANSLATIONS_EN
    },
    uk: {
        translation: TRANSLATIONS_UK
    }
};
const DETECTION_OPTIONS = {
    order: ['navigator']
  };

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: DETECTION_OPTIONS,
        resources: resource
    });
export default i18n;