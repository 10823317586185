import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { setCards, setPlayingCards, setTypeDeck, setCustomCards } from '../../Redux/cardSlice';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone'
import {
    categoriesFieldsName,
    categoriesFieldsTypes,
    cardsFieldsName,
    cardsFieldsTypes
} from '../../helpers/InputHelper';
import { pathCard } from '../../helpers/consts'
import './Import.css'

const Import = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation('translation', { keyPrefix: 'Import' });

    const [fileIsValid, setValid] = useState(false);
    const [importCards, setImportCards] = useState(false);
    const [selectedFile, setSelectedFile] = useState(undefined);
    const [notification, setNotification] = useState("");
    const [importType, setImportType] = useState("");

    const handleUploadClick = () => {
        if (fileIsValid) {
            dispatch(setCards(importCards));
            dispatch(setCustomCards(true));
            dispatch(setTypeDeck('default'));
            dispatch(setPlayingCards([]));
            navigate(`${pathCard}/`);
        }
    };

    const onDrop = (file) => {
        if (file.length === 0 || file.length >= 2) {
            setNotification(t('needUploadOnly1File'));
        }
        else {
            if (file.length === 1) {
                if (file[0].type === "application/json") {
                    setImportType('json');
                    fileReadToJSON(file[0])
                    setSelectedFile(file[0]);
                }
                else if (file[0].type === "text/plain") {
                    setImportType('txt');
                    fileReadToTXT(file[0]);
                    setSelectedFile(file[0]);
                }
                else {
                    setImportType(file[0].type);
                    setSelectedFile(file[0]);
                    setNotification(t('incorrectFormat'));
                }
            }
        }
    };

    const fileReadToJSON = (file) => {
        var reader = new FileReader();
        reader.onload = checkJSONFile;
        reader.readAsText(file);
    }

    const fileReadToTXT = (file) => {
        var reader = new FileReader();
        reader.onload = checkTxtFile;
        reader.readAsText(file);
    }

    const checkTxtFile = (event) => {
        var content = event.target.result;
        var obj = convertTxtToObject(content);
        if (checkObject(obj)) {
            setValid(true);
            setImportCards(obj);
        }
    }

    const checkJSONFile = (event) => {
        var obj = JSON.parse(event.target.result);
        if (checkObject(obj)) {
            setValid(true);
            setImportCards(obj);
        }
    }

    const convertTxtToObject = (content) => {
        var importCards = {
            categories: []
        };

        var categories = content.split('\n');
        for (let index = 0; index < categories.length; index++) {
            categories[index] = categories[index].replace('\r', '');

        }

        for (let index = 0; index < categories.length; index++) {
            const element = categories[index];

            let items = element.split('**');

            if (items.length === 2) {
                let categoriName = items[0];
                let cardsTxt = items[1].split('*');
                let cards = [];

                if (cardsTxt.length > 0) {
                    for (let index = 0; index < cardsTxt.length; index++) {
                        const element = cardsTxt[index];

                        cards.push({
                            id: index + 1,
                            content: element
                        })
                    }
                }
                else {
                    setNotification(t('noCards'));
                    return false;
                }


                let newCategories = {
                    id: index + 1,
                    title: categoriName,
                    cards: cards
                }

                importCards.categories.push(newCategories);
            }
            else {
                setNotification(t('badFormat'));
                return false;
            }
        }

        return importCards;
    }

    //validate json file
    const checkObject = (obj) => {
        let index = 0;
        if (obj.categories) {
            for (index = 0; index < obj.categories.length; index++) {
                const element = obj.categories[index];
                if (checkCategories(element)) {
                    setNotification('');
                }
                else
                    return false;
            }
            return true;
        }
        else {
            return false;
        }

    }

    const checkCategories = (categories) => {
        if (Object.keys(categories).length === categoriesFieldsName.length) {
            for (let key in categories) {
                if (categoriesFieldsName.includes(key)) {
                    let validType = typeof categories[key] === categoriesFieldsTypes[categoriesFieldsName.indexOf(key)];
                    if (!validType) {
                        if (categoriesFieldsTypes[categoriesFieldsName.indexOf(key)] === 'array'
                            && !Array.isArray(categories[key])) {
                            setNotification(`${t('field')} ${key} ${t('otherType')}`);
                            return false;
                        }
                        else {
                            if (!checkCards(categories[key])) {
                                return false;
                            }
                        }
                    }
                } else {
                    setNotification(t('missingFields'));
                    return false;
                }
            }
        } else {
            setNotification(t('missingFields'));
            return false;
        }
        return true;
    }

    const checkCards = (cards) => {
        if (Object.keys(cards).length == 0) {
            setNotification(t('missingElementsArray'));
            return false;
        }
        else {
            for (let index = 0; index < cards.length; index++) {
                const card = cards[index];
                if (Object.keys(card).length === cardsFieldsName.length) {
                    for (let key in card) {
                        if (cardsFieldsName.includes(key)) {
                            if (typeof card[key] !== cardsFieldsTypes[cardsFieldsName.indexOf(key)]) {
                                setNotification(`${t('field')} ${key} ${t('otherType')}`);
                                return false;
                            }
                        } else {
                            setNotification(t('missingFields'));
                            return false;
                        }
                    }
                } else {
                    setNotification(t('missingFields'));
                    return false;
                }
            }
        }
        return true;
    }

    return (
        <div className="container">
            {
                notification.length > 0 && (
                    <div className="notification-box-import">
                        <div className="isa_error">
                            <i className="fa fa-times-circle"></i>
                            {notification}<br />
                            {importType === 'txt' && (
                                <p className="notification-box-import-p">
                                    {t('correctFormat')}:<br />
                                    {t('categoryName')}**{t('cardName')}*{t('cardName')}<br />
                                    {t('categoryName')}**{t('cardName')}*{t('cardName')}
                                </p>
                            )}
                        </div>

                    </div>
                )
            }
            <Dropzone onDrop={onDrop} multiple={false}>
                {({ getRootProps, getInputProps }) => (
                    <section>
                        <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            {selectedFile && selectedFile.name ? (
                                <div className="selected-file">
                                    {selectedFile && selectedFile.name}
                                </div>
                            ) : (
                                t('dragADropHint')
                            )}
                        </div>
                        <aside className="selected-file-wrapper">
                            <button
                                className="btn"
                                disabled={notification.length > 0 || !selectedFile}
                                onClick={handleUploadClick}>
                                {t('upload')}
                            </button>
                        </aside>
                    </section>
                )}
            </Dropzone>
        </div>
    );
};

export default Import;