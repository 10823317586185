import React from 'react'
import { lineColor } from '../../helpers/consts'
import './CanvasCardViewB.css'

export default function CanvasCardViewB(props) {
    return (
        <div className="containterB" id="cardsGrid">
            <div className="containterB-top-item">
                <div className="cardB border-right">
                    <div className="cardB-full ">
                        <p className="categoryName">{props.cards.categories[0].title}</p>
                        <hr className="hr-card" style={{ backgroundColor: lineColor[0] }} />
                        <p className="cardContent">{props.cards.categories[0].selectedCard.content}</p>
                    </div>
                </div>
                <div className="cardB border-right">
                    <div className="cardB-half ">
                        <p className="categoryName">{props.cards.categories[1].title}</p>
                        <hr className="hr-card" style={{ backgroundColor: lineColor[1] }} />
                        <p className="cardContent">{props.cards.categories[1].selectedCard.content}</p>
                    </div>
                    <div className="cardB-half border-top">
                        <p className="categoryName">{props.cards.categories[2].title}</p>
                        <hr className="hr-card" style={{ backgroundColor: lineColor[2] }} />
                        <p className="cardContent">{props.cards.categories[2].selectedCard.content}</p>
                    </div>
                </div>
                <div className="cardB">
                    <div className="cardB-three-top-bottom">
                        <p className="categoryName">{props.cards.categories[3].title}</p>
                        <hr className="hr-card" style={{ backgroundColor: lineColor[3] }} />
                        <p className="cardContent">{props.cards.categories[3].selectedCard.content}</p>
                    </div>
                    <div className="cardB-three-main border-bottom border-top">
                        <p className="categoryName">{props.cards.categories[4].title}</p>
                        <hr className="hr-card" style={{ backgroundColor: lineColor[4] }} />
                        <p className="cardContent">{props.cards.categories[4].selectedCard.content}</p>
                    </div>
                    <div className="cardB-three-top-bottom ">
                        <p className="categoryName">{props.cards.categories[5].title}</p>
                        <hr className="hr-card" style={{ backgroundColor: lineColor[5] }} />
                        <p className="cardContent">{props.cards.categories[5].selectedCard.content}</p>
                    </div>
                </div>
                <div className="cardB border-left">
                    <div className="cardB-half ">
                        <p className="categoryName">{props.cards.categories[6].title}</p>
                        <hr className="hr-card" style={{ backgroundColor: lineColor[6] }} />
                        <p className="cardContent">{props.cards.categories[6].selectedCard.content}</p>
                    </div>
                    <div className="cardB-half border-top">
                        <p className="categoryName">{props.cards.categories[7].title}</p>
                        <hr className="hr-card" style={{ backgroundColor: lineColor[7] }} />
                        <p className="cardContent">{props.cards.categories[7].selectedCard.content}</p>
                    </div>
                </div>
                <div className="cardB border-left">
                    <div className="cardB-full">
                        <p className="categoryName">{props.cards.categories[8].title}</p>
                        <hr className="hr-card" style={{ backgroundColor: lineColor[8] }} />
                        <p className="cardContent">{props.cards.categories[8].selectedCard.content}</p>
                    </div>
                </div>
            </div>
            <div className="containterB-bottom-item border-top">
                <div className="cardB-bottom border-right">
                    <p className="categoryName">{props.cards.categories[9].title}</p>
                    <hr className="hr-card" style={{ backgroundColor: lineColor[9] }} />
                    <p className="cardContent">{props.cards.categories[9].selectedCard.content}</p>
                </div>
                <div className="cardB-bottom border-right">
                    <p className="categoryName">{props.cards.categories[10].title}</p>
                    <hr className="hr-card" style={{ backgroundColor: lineColor[10] }} />
                    <p className="cardContent">{props.cards.categories[10].selectedCard.content}</p>
                </div>
                <div className="cardB-bottom">
                    <p className="categoryName">{props.cards.categories[11].title}</p>
                    <hr className="hr-card" style={{ backgroundColor: lineColor[11] }} />
                    <p className="cardContent">{props.cards.categories[11].selectedCard.content}</p>
                </div>
            </div>
        </div>
    )
}
