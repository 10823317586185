import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate  } from "react-router-dom";
import { setCards, setPlayingCards, setTypeDeck, setCustomCards } from '../../Redux/cardSlice';
import { useTranslation } from 'react-i18next';
import { pathCard } from '../../helpers/consts'

import './AddCards.css'
export default function AddCards() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation('translation', { keyPrefix: 'AddCards' });

    const [inputList, setInputList] = useState([{
        id: 1,
        title: '', cards: []
    }]);
    const [notification, setNotification] = useState("");
    // handle input change
    const handleInputTitleChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index]['id'] = index + 1;
        list[index][name] = value;
        setInputList(list);
    };

    const handleInputCardsChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];

        let cardsArray = value.split('\n').map((element, index) => {
            return {
                id: index + 1,
                content: element
            }
        });

        list[index][name] = cardsArray.filter(item => item !== undefined);
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, {
            id: inputList.length + 1,
            title: '',
            cards: []
        }]);
    };

    const handleSaveClick = () => {
        let index = 0;
        for (index = 0; index < inputList.length; index++) {
            const element = inputList[index];
            if (element.title.length > 0) {
                if (element.cards.length == 0) {
                    setNotification(`${t('inCategory')} ${element.id} ${t('noCards')}`);
                    break;
                }
            }
            else {
                setNotification(`${t('inCategory')} ${element.id} ${t('missingTitle')}`);
                break;
            }
        }
        if (index === inputList.length) {
            setNotification('');
            inputList.map(element => {
                element.cards = element.cards.filter(item => item.content.length > 0)
            })
            dispatch(setCards({
                categories: inputList
            }));
            dispatch(setPlayingCards([]));
            dispatch(setTypeDeck('default'));
            dispatch(setCustomCards(true));
            navigate(`${pathCard}/`);
        }
    }

    const handleRemoveAllClick = () => {
        setInputList([{
            id: 1,
            title: '', cards: [{
                content: ''
            }]
        }]);
    };

    const getCardsArray = (cards) => {
        if (cards.length > 0) {
            let value = "";
            cards.map((element, index) => {
                if (index === cards.length - 1) {
                    value = value + element.content;
                }
                else {
                    value = value + element.content + '\n';
                }
            })
            return value;
        }
        else {
            return "";
        }

    }
    return (
        <div>
            <div className="tools-box">
                <button className="green" onClick={handleSaveClick}>{t('save')}</button>
                <button className="green" onClick={handleAddClick}>{t('add')}</button>
                {inputList.length > 1 && <button className="red" onClick={handleRemoveAllClick}>{t('clear')}</button>}
            </div>
            {
                notification.length > 0 && (
                    <div className="notification-box">
                        <div className="isa_error">
                            <i className="fa fa-times-circle"></i>
                            {notification}
                        </div>
                    </div>
                )
            }

            <div className="custom-form">
                {inputList.map((x, i) => {
                    return (
                        <div className="column-form" key={i}>
                            <label htmlFor="title">{t('category')}</label>
                            <input
                               className="inputTypeText"
                                name="title"
                                type="text"
                                placeholder={t('categoryHint')}
                                value={x.title}
                                onChange={e => handleInputTitleChange(e, i)}
                            />
                            <label htmlFor="title">{t('cards')}</label>
                            <textarea
                                name="cards"
                                placeholder={t('cardsHint')}
                                value={getCardsArray(x.cards)}
                                onChange={e => handleInputCardsChange(e, i)}
                            />
                            <div className="tools-item-box">
                                <div className="remove-box">
                                    {inputList.length !== 1 && <button
                                        className="mr10"
                                        onClick={() => handleRemoveClick(i)}>{t('remove')}</button>}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}
