import React from 'react'
import { useSelector } from 'react-redux'
import DefaultViewCards from '../DefaultViewCards/DefaultViewCards';
import CanvasCardView from '../CanvasCardView/CanvasCardView';
import CanvasCardViewB from '../CanvasCardViewB/CanvasCardViewB';
import './CardGrid.css';

export default function CardGrid(props) {
    const typeDeck = useSelector(({ card }) => card.typeDeck);
    return (
        <>
            {
                typeDeck === "default" && (
                    <DefaultViewCards cards={props.cards} />
                )
            }
            {
                typeDeck === "canvas" && (
                    <CanvasCardView cards={props.cards} />
                )
            }
            {
                typeDeck === "canvas-b" && (
                    <CanvasCardViewB cards={props.cards} />
                )
            }
        </>

    )
}
