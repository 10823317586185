import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import saveAs from 'file-saver';
import { pathCard } from '../../helpers/consts'
import './Export.css';

export default function Export() {
    const navigate = useNavigate();
    const cards = useSelector(({ card }) => card.card);
    const { t } = useTranslation('translation', { keyPrefix: 'Export' });

    const [fileName, setFileName] = useState("");
    const [typeDoc, setTypeDoc] = useState("txt");

    const handleInputChange = (e) => {
        const reg = /^[a-zA-Z0-9а-яА-Яіїь\b]+$/;
        if (e.target.value === '' || reg.test(e.target.value)) {
            setFileName(e.target.value);
        };
    }

    const handleSaveClick = () => {
        let fileToSave
        if (typeDoc === 'json') {
            fileToSave = new Blob([JSON.stringify(cards)], {
                type: 'application/json',
                name: fileName
            });
        }
        if (typeDoc === 'txt') {
            fileToSave = new Blob([createTxTFormat()], {
                type: "text/plain;charset=utf-8",
                name: fileName
            });
        }
        saveAs(fileToSave, fileName);
        navigate(`${pathCard}/`);
    }

    const handleTypeDoc = (e) => {
        setTypeDoc(e.target.value);
    }

    const createTxTFormat = () => {
        let str = "";
        cards.categories.map((e, index) => {
            str += `${e.title}**`;
            e.cards.map((c, index) => {
                str += `${c.content}${index != e.cards.length - 1 ? '*' : ''}`
            })
            str += `${index != cards.categories.length - 1 ? '\n' : ''}`;
        })
        return str;
    }
    return (
        <div className="form">
            <div className="inputContainer">
                <input
                    className="input"
                    name="title"
                    type="text"
                    placeholder={t('enterFileName')}
                    value={fileName}
                    onChange={e => handleInputChange(e)}
                />
                <select className="selectTypeDoc" name="typeDocExport" onChange={handleTypeDoc} defaultValue={typeDoc}>
                    <option value="json">.json</option>
                    <option value="txt">.txt</option>
                </select>
            </div>

            <div className="saveBox">
                <div className="saveBox">
                    <button onClick={handleSaveClick}>{t('save')}</button>
                </div>
            </div>
        </div>
    )
}
