export const getSelectedCard = (cards, categoriId, cardId) => {
    const categori = cards?.categories.
        find(element => element.id === categoriId);
    return categori?.cards.find(element => element.id === cardId);
}

export const generateCard = (value) => {
    let cards = JSON.parse(JSON.stringify(value));
    let genereted = true;
    for (let item of cards.categories) {
        if (item.cards.length > 0) {
            const card = item.cards[random(0, item.cards.length)];
            item.selectedCard = card;
        }
        else {
            item.selectedCard = {
                id: -1
            }
        }
    }

    if (genereted) {
        return cards;
    }
    else {
        return null;
    }
}

function random(min, max) {
    var range = max - min;
    var requestBytes = Math.ceil(Math.log2(range) / 8);
    if (!requestBytes) {
        return min;
    }
    var maxNum = Math.pow(256, requestBytes);
    var ar = new Uint8Array(requestBytes);

    while (true) {
        window.crypto.getRandomValues(ar);

        var val = 0;
        for (var i = 0; i < requestBytes; i++) {
            val = (val << 8) + ar[i];
        }

        if (val < maxNum - maxNum % range) {
            return min + (val % range);
        }
    }
}