import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { genereteCards, setPlayingCards } from '../../Redux/cardSlice';
import CardGrid from '../CardGrid/CardGrid';


export default function Game() {
    const dispatch = useDispatch();
    const cards = useSelector(({ card }) => card.card);
    const playingCards = useSelector(({ card }) => card.playingCards);

    useEffect(() => {
        if (cards?.categories) {
            dispatch(genereteCards('start'));
        }
    }, [cards]);

    useEffect(() => {
        return () =>{
            dispatch(setPlayingCards([]));
        }
    }, []);

    return (
        <>
            <div>
                {playingCards?.categories &&
                    (
                        <CardGrid cards={playingCards} />
                    )}
            </div>
        </>
    )
}
