export const TRANSLATIONS_UK = {
  "title": "ua",
  "AddCards": {
    "add": "Додати",
    "save": "Зберегти",
    "clear": "Очистити",
    "remove": "Видалити",
    "cardsHint": "Введіть карточки",
    "cards": "Карти:",
    "categoryHint" : "Введіть назву категорії",
    "category": "Назва категорії",
    "inCategory": "У категорії",
    "noCards": "відсутні картки!", 
    "missingTitle": "відсутній заголовок!",
  },
  "Export": {
    "fileName": "Назва файлу",
    "enterFileName": "Введіть назву файла",
    "save": "Зберегти" 
  },
  "Home": {
    "game": "Грати",
    "createCards": "Створити набір",
    "import": "Імпортувати",
    "export": "Експортувати",
    "changeLang": "Змінити мову"
  },
  "Import": {
    "incorrectFormat": "Не правильний тип файлу",
    "needUploadOnly1File": "Потрібно завантажити лише 1 файл",
    "field": "Поле",
    "otherType": "належить до іншого типу",
    "missingFields": "Відсутні деякі поля або присутні зайві",
    "missingElementsArray": "Відсутні елементи у масиві карток",
    "dragADropHint": "Перетягніть файл сюди або натисніть, щоб вибрати файл", 
    "more10ElementsArray": "У масиві більше 10 карт",
    "upload": "Завантажити",
    "noCards": "Відсутні картки",
    "badFormat": "Поганий формат файлу",
    "correctFormat": "Правильний формат",
    "categoryName": "Назва категорії",
    "cardName": "назва картки"
  },
  "Layout": {
    "home": "Головна",
    "saveToPDF": "Зберегти у PDF",
    "generate": "Згенерувати картки"
  }
}