export const TRANSLATIONS_EN = {
  "title": "Welcome to react using react-i18next",
  "AddCards": {
    "add": "Add",
    "save": "Save",
    "clear": "Clear",
    "remove": "Remove",
    "cardsHint": "Enter cards",
    "cards": "Cards:",
    "categoryHint": "Enter category",
    "category": "Category name",
    "inCategory": "In the category",
    "noCards": "no cards!",
    "missingTitle": "missing title!",
  },
  "Export": {
    "fileName": "File name",
    "enterFileName": "Enter file name",
    "save": "Save"
  },
  "Home": {
    "game": "Play",
    "createCards": "Create set",
    "import": "Import",
    "export": "Export",
    "changeLang": "Change language"
  },
  "Import": {
    "incorrectFormat": "Incorrect file type",
    "needUploadOnly1File": "Only 1 file needs to be downloaded",
    "field": "Field",
    "otherType": "belongs to another type",
    "missingFields": "Missing some fields or extra fields",
    "missingElementsArray": "Missing elements in the map array",
    "dragADropHint": "Drag file here or click to select file",
    "more10ElementsArray": "There are more than 10 cards in array",
    "upload": "Upload",
    "noCards": "No cards",
    "badFormat": "Bad file format",
    "correctFormat": "The correct format",
    "categoryName": "Category name",
    "cardName": "Card name"
  },
  "Layout": {
    "home": "Home",
    "saveToPDF": "Save to PDF",
    "generate": "Generate cards"
  }
}