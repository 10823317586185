export const pathCard = "";
export const widthCard = 920;
export const heightCard = 225;

export const lineColor = [
    "#13A49B",
    "#FBE90B",
    "#3FAB3C",
    "#38A9E1",
    "#982C82",
    "#CE2F33",
    "#534592",
    "#F39318",
    "#E61F72",
    "#43F0FF",
    "#7E28BF",
    "#26B53E"
];