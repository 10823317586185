import React, { } from 'react';
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { GrLanguage } from 'react-icons/gr';
import { useTranslation } from 'react-i18next';
import { pathCard } from '../../helpers/consts';
import { setCards } from '../../Redux/cardSlice';
import dt from '../../helpers/defaultCards.json';
import './Home.css'


export default function Home() {
    const dispatch = useDispatch();
    const langs = {
        'uk': 'en',
        'en': 'uk'
    }
    const cards = useSelector(({ card }) => card.card);
    const customCards = useSelector(({ card }) => card.customCards);
    const { i18n } = useTranslation();
    const { t } = useTranslation('translation', { keyPrefix: 'Home' });

    const handleChangeLang = (e) => {
        let element = document.getElementById('lang-container');
        let langAtr = element.getAttribute('lang');
        let lang = langs[langAtr];
        element.setAttribute('lang', lang);
        i18n.changeLanguage(lang);
        if(!customCards){
            dispatch(setCards(dt[lang]));
        }
    }
    return (
        <>
            <div className="navigation">
                {cards?.categories && (
                    <Link to={`${pathCard}/game`}>{t('game')}</Link>
                )}
                <Link to={`${pathCard}/create`}>{t('createCards')}</Link>
                <Link to={`${pathCard}/import`}>{t('import')}</Link>
                {cards?.categories && (
                    <Link to={`${pathCard}/export`}>{t('export')}</Link>
                )}
                <div className="changeLanguage">
                    <a lang={'uk'} id={'lang-container'} onClick={handleChangeLang}>
                        <GrLanguage style={{ paddingRight: '0.5vw' }} />
                    </a>
                </div>

            </div>
        </>
    )
}
