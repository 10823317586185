import React from 'react'
import { lineColor } from '../../helpers/consts'
import './CanvasCardView.css'

export default function CanvasCardView(props) {

    return (
        <div className="containter" id="cardsGrid">
            <div className="top-line">
                <div className="top-card">
                    <div className="top-card-full">
                        <p className="categoryName">{props.cards.categories[0].title}</p>
                        <hr className="hr-card" style={{ backgroundColor: lineColor[0] }} />
                        <p className="cardContent">{props.cards.categories[0].selectedCard.content}</p>
                    </div>
                </div>
                <div className="top-card border-left-right">
                    <div className="top-card-half ">
                        <p className="categoryName">{props.cards.categories[1].title}</p>
                        <hr className="hr-card" style={{ backgroundColor: lineColor[1] }} />
                        <p className="cardContent">{props.cards.categories[1].selectedCard.content}</p>
                    </div>
                    <div className="top-card-half border-top">
                        <p className="categoryName">{props.cards.categories[2].title}</p>
                        <hr className="hr-card" style={{ backgroundColor: lineColor[2] }} />
                        <p className="cardContent">{props.cards.categories[2].selectedCard.content}</p>
                    </div>
                </div>
                <div className="top-card">
                    <div className="top-card-full">
                        <p className="categoryName">{props.cards.categories[3].title}</p>
                        <hr className="hr-card" style={{ backgroundColor: lineColor[3] }} />
                        <p className="cardContent">{props.cards.categories[3].selectedCard.content}</p>
                    </div>
                </div>
                <div className="top-card border-left-right">
                    <div className="top-card-half">
                        <p className="categoryName">{props.cards.categories[4].title}</p>
                        <hr className="hr-card" style={{ backgroundColor: lineColor[4] }} />
                        <p className="cardContent">{props.cards.categories[4].selectedCard.content}</p>
                    </div>
                    <div className="top-card-half border-top">
                        <p className="categoryName">{props.cards.categories[5].title}</p>
                        <hr className="hr-card" style={{ backgroundColor: lineColor[5] }} />
                        <p className="cardContent">{props.cards.categories[5].selectedCard.content}</p>
                    </div>
                </div>
                <div className="top-card">
                    <div className="top-card-full">
                        <p className="categoryName">{props.cards.categories[6].title}</p>
                        <hr className="hr-card" style={{ backgroundColor: lineColor[6] }} />
                        <p className="cardContent">{props.cards.categories[6].selectedCard.content}</p>
                    </div>
                </div>
            </div>
            <div className="bottom-line">
                <div className="bottom-card">
                    <p className="categoryName">{props.cards.categories[7].title}</p>
                    <hr className="hr-card" style={{ backgroundColor: lineColor[7] }} />
                    <p className="cardContent">{props.cards.categories[7].selectedCard.content}</p>
                </div>
                <div className="bottom-card bottom-card-right">
                    <p className="categoryName">{props.cards.categories[8].title}</p>
                    <hr className="hr-card" style={{ backgroundColor: lineColor[8] }} />
                    <p className="cardContent">{props.cards.categories[8].selectedCard.content}</p>
                </div>
            </div>
        </div>
    )
}
