import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom';
import { genereteCards } from '../../Redux/cardSlice';
import { setTypeDeck } from '../../Redux/cardSlice';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import * as htmlToImage from 'html-to-image';
import { jsPDF } from "jspdf";
import { widthCard, heightCard, pathCard } from '../../helpers/consts'
import './Layout.css'

export default function Layout() {
    const { t } = useTranslation('translation', { keyPrefix: 'Layout' });
    const playingCards = useSelector(({ card }) => card.playingCards);
    const typeDeck = useSelector(({ card }) => card.typeDeck);
    const dispatch = useDispatch();
    const [path, setPath] = useState(window.location.pathname);
    const location = useLocation();

    useEffect(() => {
        setPath(location.pathname);
    }, [location]);

    const handleGenerateClick = () => {
        dispatch(genereteCards());
    }

    const handleToPDF = () => {
        htmlToImage.toPng(document.getElementById('cardsGrid'), { quality: 1 })
            .then(function (dataUrl) {
                let countCards = playingCards.categories.length;
                let multiplier = Math.ceil(countCards / 5);
                let height = heightCard * multiplier;
                const pdf = new jsPDF('l', 'pt', [height, widthCard]);
                pdf.addImage(dataUrl, 'PNG', 0, 0, widthCard, height);
                pdf.save("saveToPDFDeck.pdf");
            });
    }

    const handleDeckChange = (e) => {
        dispatch(setTypeDeck(e.target.value))
    }
    return (
        <>
            <header>
                <div className="header-btn-container">
                    {
                        path !== `${pathCard}/` && path !== `${pathCard}/home` && path !== `${pathCard}` && (
                            <Link to={`${pathCard}/`}><p>{t('home')}</p></Link>
                        )
                    }
                    {
                        path === `${pathCard}/game` && (
                            <>
                                <button onClick={handleToPDF}>
                                    <p>{t('saveToPDF')}</p>
                                </button>
                                <button onClick={handleGenerateClick}>
                                    <p>{t('generate')}</p>
                                </button>
                                {
                                    playingCards.categories.length === 9 || playingCards.categories.length === 12 ? (
                                        <select className="select" name="typeDeck" onChange={handleDeckChange} defaultValue={typeDeck}>
                                            <option value="default">Default</option>
                                            {
                                                playingCards.categories.length === 9 && (
                                                    <option value="canvas">Business Model Canvas</option>
                                                )
                                            }
                                            {
                                                playingCards.categories.length === 12 && (
                                                    <option value="canvas-b">The "B" Canvas Business Model</option>
                                                )
                                            }
                                        </select>
                                    ) : null
                                }
                            </>
                        )
                    }
                </div>
                <div className="logo-container">
                    Business Canvas Cards Game
                </div>
            </header>
        </>
    )
}
