import { createSlice } from '@reduxjs/toolkit';
import { generateCard } from "../helpers/Utility";

export const cardSlice = createSlice({
  name: 'card',
  initialState: {
    card: [],
    playingCards: [],
    typeDeck: "default",
    customCards: false
  },
  reducers: {
    setCards: (state, action) => {
      state.card = action.payload
    },
    setPlayingCards: (state, action) => {
      state.playingCards = action.payload
    },
    genereteCards: (state, action) => {
      let generetedCards;
      if (action.payload === 'start') {
        generetedCards = generateCard(state.card);
      }
      else {
        generetedCards = generateCard(state.playingCards);
      }

      state.playingCards = generetedCards;
    },
    setTypeDeck: (state, action) => {
      state.typeDeck = action.payload
    },
    setCustomCards: (state, action) => {
      state.customCards = action.payload
    }
  },
})

export const {
  setCards,
  setPlayingCards,
  genereteCards,
  setTypeDeck,
  setCustomCards
} = cardSlice.actions

export default cardSlice.reducer