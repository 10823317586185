import React from 'react'
import './Card.css'; 

export default function Card(props) {
    return (
        <div className="card" style={{ borderLeft: `2.1vh ${props.lineColor} solid`}}>
            <div className="card-categories">
                <p>{props.title}</p>
            </div>
            <div className="card-content">
                <p>{props.content}</p>
            </div>
        </div>
    )
}
