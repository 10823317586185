import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Layout from '../Layout/Layout'
import Home from '../Home/Home'
import Game from '../Game/Game'
import AddCards from '../AddCards/AddCards'
import Export from '../Export/Export';
import Import from '../Import/Import';
import Footer from '../Footer/Footer';
import dt from '../../helpers/defaultCards.json';
import { pathCard } from '../../helpers/consts'
import { setCards } from '../../Redux/cardSlice';
import './App.css';


export default function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage('uk');
    dispatch(setCards(dt['uk']));
  }, [])

  return (
    <>
      <Router>
        <Layout />
        <main>
          <Routes>
            <Route exact path={`${pathCard}/`} element={<Home />} />
            <Route exact path={`${pathCard}/game`} element={<Game />} />
            <Route exact path={`${pathCard}/create`} element={<AddCards />} />
            <Route exact path={`${pathCard}/export`} element={<Export />} />
            <Route exact path={`${pathCard}/import`} element={<Import />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </main>
        <Footer />
      </Router>
    </>
  );
}
