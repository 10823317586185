import React from 'react'
import './Footer.css'
export default function Footer() {

    return (
        <div className="footer">
            <div className="footer-content">
                <img src={process.env.PUBLIC_URL + '/logo.png'} />
            </div>
        </div>
    )
}
