import React from 'react'
import { useTranslation } from 'react-i18next';
import Card from '../Card/Card';
import { lineColor } from '../../helpers/consts'
import './DefaultViewCards.css';

export default function DefaultViewCards(props) {
    const { t } = useTranslation('translation', { keyPrefix: 'Game' });
    let i = 0;

    const setNull = (index) =>{
        i = 0;
        return index;
    }

    return (
        <div className="grid-table" id="cardsGrid">
            {props.cards?.categories.map((item, index) => {
                return <Card key={index} lineColor={lineColor[i === 0 && index > 12 ? i++ : i === 11 ? setNull(index) : i++]} title={item.title} content={item?.selectedCard?.content} />
            })}
        </div>
    )
}
